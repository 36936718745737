.container1 {
    margin-top: 6%;
}

/* head {
    margin-bottom: 5%;
} */

.wrapper {
    margin-top: 10%;
    background-color: aliceblue;
    margin-bottom: 20px;
}

.wrapper h1 {
    margin-bottom: 60px;
    font-size: 52px;
}

.team {
    display: flex;
    text-align: center;
    width: auto;
    justify-content: center;
}

.team .team_member {
    background: #fff;
    margin: 5px;
    margin-bottom: 50px;
    width: 300px;
    padding: 20px;
    line-height: 20px;
    color: #8e8b8b;
    position: relative;
    /* border: 2px solid rgb(23, 23, 23); */
    border-radius: 2%;
    height: 85%;
}

.team_member:hover{
    border-color: #2a7be4!important;
    border: 2px solid;
    box-shadow: 10px 10px 15px gray;
}

.team .team_member h3 {
    color: #81c644;
    font-size: 26px;
    margin-top: 50px;

}

.team .team_member p.role {
    color: #ccc;
    font-size: 12px;
    text-transform: uppercase;
    margin: 12px 0;
}

.about_img {

    width: 40%;
    position: absolute;
    top: -60px;
    right: 30%;


}

.abourH1 {
    padding-top: 20px;
    padding-bottom: 10px;
}



/* .about_img:hover{
    border-color: #2a7be4!important;
    border: 2px solid;
    box-shadow: 10px 10px 15px gray;
    border-radius: 50%;
} */


.about-bg{
    background-color: #EFF0FF;
    height: 100%;
    width: 100%;
}


.soi1 {
    color: black;
}

@media screen and (max-width: 560px) {
    .team {
        margin-top: 100px;
        margin-left: 15%;

    }

    .teamF {
        gap: 30px;
    }
}