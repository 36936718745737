/* form{
    height: 100px;
    width: 100%;
} */
label {
    margin-left: 4%;
}

.p-card {
    width: 424px;
    height: 360px;
    transform: rotate(90deg);
    background: #212529;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    transition-duration: 1.5s;
    transition-property: width, height, transform;
}

.p-card:hover {
    width: 360px;
    height: 424px;
    transform: rotate(0deg);
    /* -ms-transform:rotate(90deg);
     -webkit-transform:rotate(90deg); */
}

.p-card__content {
    padding: 1rem;
    font-size: smaller;
}

.p-card__date {
    color: white;
    margin-bottom: .5rem;
    font-weight: 700;
}

.p-card__info {
    width: 100%;
    height: 370px;
    padding: .7rem;
    font-size: smaller;
    border-radius: 1rem;
    text-align: start;
    background-color: rgba(188, 191, 227);
    color: rgba(188, 191, 227, 0.6);
    transition-duration: 1s;
    transition-property: color;
}

.p-card__info:hover {
    color: #212121;
    padding-top: 40px;
}