.submit-area {
    margin-top: 30px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px gray;
    padding: 50px;
    background-color: rgb(255, 255, 255);
}

.img-area {
    margin-top: 55px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px gray;
    padding: 5px;
}

.bsub {
    width: 100%;
}

.box {
    background-color: aliceblue;
    margin-top: 5%;
    padding: 2%;

}

.log-text{

}

.animated-text .text-span {
    position: relative;
}

.animated-text .text-span::before {
    content: "Friends.";
    color: #28a745;
    animation: words 7s infinite;
    line-height: 1.5;
    font-size: 1.2em;
    font-weight: 600;
}


.animated-text .text-span::after {
    content: "";
    position: relative;
    width: 10px;
    height: 100%;
    background-color: aliceblue;
    /* border-left: .5px solid #28a745;
    right: -5px;
    animation: cursor .2s infinite; */
}

@keyframes cursor {
    to {
        border-left: 2px solid aliceblue;
        animation-duration: 1.5s;
    }
}

@keyframes words {

    0%,
    25% {
        content: "Friends.";
    }

    26%,
    50% {
        content: "Classmates.";

    }

    51%,
    75% {
        content: "Seniors.";

    }

    76%,
    100% {
        content: "Juniors.";

    }

}


.shadow-btn {
    padding: 10px 20px;
    border: none;
    font-size: 17px;
    color: #fff;
    border-radius: 7px;
    letter-spacing: 4px;
    font-weight: 700;
    text-transform: uppercase;
    transition: 0.5s;
    transition-property: box-shadow;

}

.shadow-btn {
    background: rgb(255, 25, 0);
    box-shadow: 0 0 25px rgb(113, 9, 9);
}

.shadow-btn:hover {
    box-shadow: 0 0 5px rgb(168, 28, 26),
        0 0 25px rgb(206, 14, 14),
        0 0 50px rgb(187, 11, 11),
        0 0 100px rgb(228, 7, 7);
}





[class^="number-slide"],
[class*=" number-slide"] {
    background: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: #fff;
    font-weight: 500;
    height: 200px;
    max-height: 100vh;
}

.number-slide1 {
    background: rgb(64, 175, 255);
    background: linear-gradient(128deg,
            rgba(64, 175, 255, 1) 0%,
            rgba(63, 97, 255, 1) 100%);
}

.number-slide2 {
    background: rgb(255, 75, 64);
    background: linear-gradient(128deg,
            rgba(255, 154, 63, 1) 0%,
            rgba(255, 75, 64, 1) 100%);
}

.number-slide3 {
    background: rgb(182, 255, 64);
    background: linear-gradient(128deg,
            rgba(182, 255, 64, 1) 0%,
            rgba(63, 255, 71, 1) 100%);
    background: linear-gradient(128deg,
            rgba(189, 255, 83, 1) 0%,
            rgba(43, 250, 82, 1) 100%);
}

.number-slide4 {
    background: rgb(64, 255, 242);
    background: linear-gradient(128deg,
            rgba(64, 255, 242, 1) 0%,
            rgba(63, 188, 255, 1) 100%);
}

.number-slide5 {
    background: rgb(255, 64, 156);
    background: linear-gradient(128deg,
            rgba(255, 64, 156, 1) 0%,
            rgba(255, 63, 63, 1) 100%);
}

.number-slide6 {
    background: rgb(64, 76, 255);
    background: linear-gradient(128deg,
            rgba(64, 76, 255, 1) 0%,
            rgba(174, 63, 255, 1) 100%);
}

.wrapper-x {
    display: flex;
    justify-content: center;
}

.scene {
    width: 260px;
    height: 200px;
    perspective: 1000px;
    position: relative;
}

.scene .carousel.keen-slider {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: visible;
    transform: translateZ(-288px);
    transform-style: preserve-3d;
}

.carousel__cell {
    position: absolute;
    width: 240px;
    left: 10px;
    height: 200px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.img7 {
    width: 100%;
    height: 100%;
}

.homeStories1 {
    margin-bottom: 20px;
}



/*  */
.home-pic {
    background-color: #e8e8e8;
}




/* New */

.home-card {
    overflow: visible;
    position: relative;
    width: 190px;
    height: 254px;
    background: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}

.home-card:before,
.home-card:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: #fff;
    transition: 0.5s;
    z-index: -99;
}

.home-details {
    position: absolute;
    left: -10px;
    right: 0;
    bottom: 5px;
    height: 60px;
    text-align: center;
    text-transform: uppercase;
}

/*Image*/
.home-imgbox {
    position: absolute;
    top: 10px;
    left: 10px;
    bottom: 10px;
    right: 10px;
    background: #222;
    transition: 0.5s;
    z-index: 1;
}

.home-img {
    /* background: #4158D0;
    background-image: linear-gradient(45deg, #4158D0, #C850C0); */
    background: #EFF0FF;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}



/*Text*/
.home-title {
    font-weight: 500;
    font-size: 15px;
    color: #777;
}

.home-caption {
    font-weight: 500;
    font-size: 16px;
    color: #4158D0;
    display: block;
    margin-top: 5px;
}

/*Hover*/
.home-card:hover .home-imgbox {
    bottom: 80px;
}

.home-card:hover:before {
    transform: rotate(20deg);
}

.home-card:hover:after {
    transform: rotate(10deg);
    box-shadow: 0 2px 20px rgba(0, 0, 0, .2);
}