@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

.eventReg-head {
    margin-bottom: 50px;
    color: rgb(31, 29, 28);
}

.black-label {
    margin-top: 30px;
}

.top-head {
    font-family: 'Roboto', sans-serif;
    background: rgb(204, 204, 204);
    box-shadow: -2px 0px 33px 0px rgb(0, 0, 0, 0.75);
    margin: 20px;

}

.black-label {
    width: 80%;
}

.black-title-p {
    font-size: 20px;
    color: gray;


}

.black-title {
    font-size: 40px;
}

.prix {
    margin: 50px 0px;
    width: 45%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    border-left: 4px solid rgb(255, 177, 33);
    margin-bottom: 10px;



}

.prix-span {
    padding-left: 10px;
}

.crt {
    color: gray;
    left: 2px;
    border-bottom: 1px solid rgb(177, 177, 177);
    margin-right: 5px;

}





/* hojo */
/* Body */
.evt-container {
    display: grid;
    grid-template-columns: auto;
    gap: 0px;
}

.evt-hr {
    height: 2px;
    background-color: rgba(16, 86, 82, .75);
    border: none;
}

.evt-card {
    width: 400px;
    background: rgb(255, 250, 235);
    box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01), 0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09), 0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.evt-title {
    width: 100%;
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 20px;
    /* border-bottom: 1px solid rgba(16, 86, 82, .75); */
    font-weight: 700;
    font-size: 18px;
    color: #000000;
}

/* Cart */
.evt-cart {
    border-radius: 19px 19px 0px 0px;
}

.evt-cart .evt-steps {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.evt-cart .evt-steps .evt-step {
    display: grid;
    gap: 10px;
}

.evt-cart .evt-steps .evt-step span {
    font-size: 13px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 8px;
    display: block;
}

.evt-cart .evt-steps .evt-step p {
    font-size: 11px;
    font-weight: 600;
    color: #000000;
}

/* Promo */
.evt-promo .evt-form {
    display: grid;
    grid-template-columns: 1fr 80px;
    gap: 10px;
    padding: 0px;
}

.evt-input_field {
    width: auto;
    height: 36px;
    padding: 0 0 0 12px;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgb(16, 86, 82);
    background-color: rgb(251, 243, 228);
    transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
    margin: 0px 0px 10px 10px;
}

.evt-input_field:focus {
    border: 1px solid transparent;
    box-shadow: 0px 0px 0px 2px rgb(251, 243, 228);
    background-color: rgb(201, 193, 178);
}

.evt-promo .evt-form button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 10px;
    width: 100%;
    height: 36px;
    background: rgba(16, 86, 82, .75);
    box-shadow: 0px 0.5px 0.5px #F3D2C9, 0px 1px 0.5px rgba(239, 239, 239, 0.5);
    border-radius: 5px;
    border: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
}

/* Checkout */
.evt-payments .evt-details {
    display: grid;
    grid-template-columns: 10fr 1fr;
    padding: 0px;
    gap: 5px;
}

.evt-payments .evt-details span:nth-child(odd) {
    font-size: 12px;
    font-weight: 600;
    color: #000000;
    margin: auto auto auto 0;
}

.evt-payments .evt-details span:nth-child(even) {
    font-size: 13px;
    font-weight: 600;
    color: #000000;
    margin: auto 0 auto auto;
}

.evt-checkout .evt-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 20px;
    background-color: rgba(16, 86, 82, .5);
}

.evt-price {
    position: relative;
    font-size: 22px;
    color: #2B2B2F;
    font-weight: 900;
}

.evt-checkout .evt-checkout-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 36px;
    background: rgba(16, 86, 82, .55);
    box-shadow: 0px 0.5px 0.5px rgba(16, 86, 82, .75), 0px 1px 0.5px rgba(16, 86, 82, .75);
    border-radius: 7px;
    border: 1px solid rgb(16, 86, 82);
    color: #000000;
    font-size: 13px;
    font-weight: 600;
    transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}