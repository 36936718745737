* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

a {
    text-decoration: none;

}

ul {
    list-style: none;
}

body {
    margin: 0px;
    padding: 0px;

}

.blog {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px;
    border-radius: 1px solid rgba(0, 0, 0, 0.05);
    margin-top: 5%;
}

.blog-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.blog-heading span {
    color: red;
}

.blog-heading h3 {
    font-size: 2.4rem;
    color: rgb(61, 55, 55);
    font-weight: 600;

}

.blog-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    flex-wrap: wrap;
}

.blog-box {
    width: 350px;
    background-color: rgb(252, 252, 252);
    border: 1px solid whitesmoke;
    margin: 20px;
}

.blog-img {
    width: 100%;
    height: 250px;

}

.blog-img img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    object-position: center;
}

.blog-text {
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.blog-text span {
    color: red;
    font-size: 0.9rem;
}

.blog-text .blog-title {
    font-size: 1.3rem;
    font-weight: 500;
    color: black;
}

.blog-text .blog-title:hover {
    color: red;
    transition: all ease 0.3s;

}

.blog-text p {
    color: rgb(188, 189, 191);
    font-size: 0.9rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 20px 0px;
}

.blog-text a {
    color: black;

}

.blog-text a:hover {
    color: red;
    transition: all ease 0.3s;
}




/* New Section */


form {
    width: 90%;

}



.article-btn {
    padding: 10px 0;
    color: var(--gray-110);
    outline: none;
    background: transparent;
    border: 1px solid rgb(74, 69, 69);
    width: 100%;
    cursor: pointer;
    margin-top: 20px;
    font-weight: 300;

}


.upperCaseHeader {
    color: var(--gray-110);
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .5px;
    line-height: 17px;
    text-transform: uppercase;
    margin-top: -20px;
}


.left-part {
    align-items: center;
    background: transparent;
    display: flex;
    justify-content: center;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f0f0f0;

}


.input-Area {
    margin-left: 0;
}

.text-area {
    height: 100px;
}

.form-h5 {
    color: var(--gray-110);
    font-weight: 700;
    margin-bottom: 40PX;
}

.title {
    margin-bottom: 50px;
}

/* New Part End */


.Up-h5 {
    color: var(--gray-110);
    font-weight: 700;
    margin-top: 30px;

}

.Up-title {
    margin-bottom: 50px;


}

.icon-art {
    margin-right: 10px;
}


/* tooltip */

.article-tooltip-container {
    position: relative;
    display: inline-block;
    margin: 20px;
}

.article-tooltip-text {
    color: #333;
    font-size: 18px;
    cursor: pointer;
}

.article-tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    background: #FFFDD0;
    color: #fff;
    padding: 10px;
    border-radius: 4px;
    transition: opacity 0.3s, visibility 0.3s, top 0.3s, background 0.3s;
    z-index: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.article-tooltip::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #FFFDD0 transparent;
    transform: translateX(-50%);
}

.article-tooltip-container:hover .article-tooltip {
    top: 120%;
    opacity: 1;
    visibility: visible;
    background: #FFFDD0;
    transform: translate(-50%, -5px);
}