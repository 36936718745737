.bg-contact {
    background: #EFF0FF;
}

.bg-get-board {
    width: 70%;
    height: 500px;
    /* flex-shrink: 0; */
    background: #DAE1E9;
    box-shadow: 4px 11px 13px 0px rgba(0, 0, 0, 0.25), 5px 6px 24px 0px rgba(0, 0, 0, 0.25) inset;
}

@media (max-width: 320px) {
    .bg-get-board {
        width: 100%;
        padding: 15px;
        font-size: 10px;
    }
}

@media (max-width: 560px) {
    .bg-get-board {
        width: 100%;
        padding: 15px;
        font-size: 10px;
    }
}

@media (max-width: 1200px) {
    .bg-get-board {
        width: 100%;
        padding: 15px;
    }
}

