.custom-card {
    border: 1px solid #ced4da;
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    background-color: white;
    max-width: 600px;
}

.custom-card:hover {
    transform: scale(1.02);
    border: 2px solid #52a4fb;
}

.custom-title {
    color: #007bff;
    margin-bottom: 15px;
}

.custom-text {
    color: #495057;
    margin-bottom: 10px;
}

.custom-btn {
    background-color: #007bff;
    border: none;
    color: white;
    border-radius: 8px;
    padding: 10px 20px;
    transition: background-color 0.3s;
}

.custom-btn:hover {
    background-color: #0056b3;
}

.custom-b-t {
    color: #495057;
    border: none;
}

.custom-color {
    color: #007bff;
    border: none;
}


.dash-b {
    background: linear-gradient(154deg, #EBF4FF 5.1%, #6E7FC3 64.75%, rgba(53, 44, 151, 0.64) 98.31%);
}

.dash-head {
    color: #000;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px;
    /* 130% */
    opacity: 0.8;
    margin-left: 80px;
}


.text-out-back {
    width: 120px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 16px;
    background: rgba(251, 63, 63, 0.43);
}

/* for upper section of dashboard */


.custom-card-body-b {
    border-radius: 28px;
    background: linear-gradient(90deg, rgba(0, 133, 255, 0.50) 0%, rgba(23, 8, 115, 0.50) 100%);
    border: none;
}

.custom-card-body-p {
    border-radius: 28px;
    background: linear-gradient(90deg, rgba(160, 94, 227, 0.50) 0%, rgba(140, 0, 143, 0.50) 100%);
    border: none;
}


.bg-shadow {
    box-shadow: 10px 11px 9px 0px rgba(0, 0, 0, 0.25);
}

.cards-body-up .cards-body-low:hover {
    transform: scale(1.2, 1.2);
}

.cards-body-up:hover>.cards-body-low:not(:hover) {
    filter: blur(1px);
    transform: scale(0.9, 0.9);
}
